import React, { useState } from 'react'
import './App.css';
import About from './components/about';
import FAQ from './components/faq';
import Fassion from './components/fassion';
import Footer from './components/footer';
import Hero from './components/hero';
import Rarity from './components/rarity';
import RoadMap from './components/roadMap';
import Slides from './components/slides';
import Team from './components/team';
import Utility from './components/utility';
import Work from './components/work';
import { BrowserRouter as Router, Route, Routes, useLocation, Navigate } from "react-router-dom";
import Privacy from './components/privacy';
import Terms from './components/terms';
import Power from './components/power';
import Product from './components/product';
import Shop from './components/shop';
import Staking from './components/staking';
import Road from './components/road';
import Intro from './components/intro';
import Header from './components/header';
import Social from './components/social';
import Powered from './components/powered';
import Tokenomics from './components/tokenomics';

function App() {
  return (
    <div className="App">
      <Router>
        <Navigation/>
      </Router>
    </div>
  );
}

function Navigation() {
  const location = useLocation();
  const background = location.state && location.state.background;
  return(
    <Routes location={background || location}>
      <Route path='*' element={<Navigate to={"/"}/>}/>
      <Route path='/' element={<AppComp/>}/>
      <Route path='/faq' element={<FAQ/>}/>
      <Route path='/privacy' element={<Privacy/>}/>
      <Route path='/terms' element={<Terms/>}/>
      <Route path='/product' element={<Product/>}/>
      <Route path='/shop' element={<Staking/>}/>
      <Route path='/staking' element={<Staking/>}/>
      <Route path='/howitworks' element={<HowItWorks/>}/>
      <Route path='/tokenomics' element={<Tokenomics/>}/>
    </Routes>
  )
}

function AppComp(){
  const [load, setLoad] = useState(true)
  return(
    <>
    <div className={load? 'Loader' : 'Loader LoadHide'}>
      <div className='MenuCon'/>
      <h1>Loading . . .</h1>
    </div>
      <Header/>
      <Hero getLoad={setLoad}/>
      {/* <Slides/> */}
      <Intro/>
      {/* <Social/> */}
      {/* <Fassion/> */}
      <About/>
      <Power/>
      <Utility/>
      <Road/>
      {/* <RoadMap/> */}
      <Team/>
      <Powered/>
      <Footer/>
    </>
  )
}

function HowItWorks(){
  return(
    <>
      <Header/>
      <Work/>
      <Rarity/>
    </>
  )
}

export default App;

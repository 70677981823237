import React, { useState } from 'react'
import './style.css'
import { Link, useLocation } from 'react-router-dom'
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';

export default function Header() {
    const [open, setOpen] = useState(false);
    const location = useLocation()
    return (
        <div className={location?.pathname === '/howitworks' ? 'NavBar NavBar_Grey' : 'NavBar'}>
            <div className='NavBar_Con'>
                <Link to={"/"} style={{ textDecoration: "none" }}>
                    <div className='Logo'>RHOX</div>
                </Link>
                <div className='FlexContainer' />
                <Link to={"/howitworks"} style={{ textDecoration: "none" }}>
                    <p className='MenuText'>how.it.works</p>
                </Link>
                <Link to={"/shop"} style={{ textDecoration: "none" }}>
                    <p className='MenuText'>airdrop</p>
                </Link>
                <Link to={"/staking"} style={{ textDecoration: "none" }}>
                    <p className='MenuText'>get.the.apparel</p>
                </Link>
                <div className='FlexContainer' />
                <div tabIndex={0} className='MenuCon' onClick={() => setOpen(!open)} onBlur={(e) => {
                    if (e.currentTarget.contains(e.relatedTarget)) return
                    else setOpen(false)
                }}>
                    {open ?
                        <AiOutlineClose style={{ marginRight: 15 }} size={13} color={"#000"} />
                        :
                        <AiOutlineMenu style={{ marginRight: 15 }} size={13} color={"#000"} />
                    }
                    <div className={open ? 'MenuBgCon MenuShow' : 'MenuBgCon'}>
                        <ul>
                            <li>Connect Wallet</li>
                            {/* <li>Whitepaper</li> */}
                            <Link to={'/tokenomics'} style={{ textDecoration: "none" }}>
                                <li>Tokenomics</li>
                            </Link>
                            <Link style={{ textDecoration: "none", color: "#000" }} to={"/faq"}><li>FAQ</li></Link>
                        </ul>
                    </div>
                    <div className={open ? 'MenuBgCon1 MenuShow1' : 'MenuBgCon1'}>
                        <ul>
                            <li>connect wallet</li>
                            <Link to={"/howitworks"} style={{ textDecoration: "none" }}>
                                <li>how.it.works</li>
                            </Link>
                            <Link to={"/shop"} style={{ textDecoration: "none" }}>
                                <li>airdrop</li>
                            </Link>
                            <Link to={"/staking"} style={{ textDecoration: "none" }}>
                                <li>get.the.apparel</li>
                            </Link>
                            <Link to={'/tokenomics'} style={{ textDecoration: "none" }}>
                                <li>tokenomics</li>
                            </Link>
                            <Link style={{ textDecoration: "none", color: "#000" }} to={"/faq"}><li>FAQ</li></Link>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

import React from 'react'

export default function Terms() {
  return (
    <div className='Privacy'>
        <div className='PrivacyCon'>
            <h1>Introduction to Terms and Conditions</h1>
            <p>These Terms and Conditions apply between all Users of this Website (including but not limited to Members), and RHOX NFT, the owner(s) of this Website. Additionally these Terms and Conditions apply to the use of and (where applicable) purchase of NFT’s and Services. Please read these Terms and Conditions carefully, as they affect your legal rights.</p>
            <h1>1. Definitions and Interpretations</h1>
            <p>1.1.Throughout these Terms and Conditions, the following words will have the meanings set against them:<br/><br/>
"AED" means United Arab Dirhams, or the lawful currency for the time being in the UAE;<br/><br/>
"Business Day" means a day that is not a Saturday, Sunday or any other day which is declared by the UAE government to be a holiday for the private sector in the UAE;<br/><br/>
"Content" means any text, graphics, images, audio, video, software, data compilations, page layout, underlying code and software and any other form of information capable of being stored in a computer that appears on or forms part of this Website;<br/><br/>
"DIFC" means the Dubai International Financial Centre;<br/><br/>
"DIFC-LCIA" means the Dubai International Financial Centre London Court of International Arbitration;<br/><br/>
"Privacy Policy" means the policy relating to privacy and date use found on the Privacy Policy web page;<br/><br/>
"RHOX NFT" means RHOX  [DMCC], a company incorporated in the Free Zone;<br/><br/>
"Services" means access for community members to the services set out on the web page using the RHOX NFT at the discounted rates set out against each NFT purchase in accordance with these Terms and Conditions;<br/><br/>
"UAE" means the United Arab Emirates;<br/><br/>
"User" means any and all persons who access the Website and use the Services or provided; and<br/><br/>
"Website" means this website, owned by RHOX NFT with the url: <a href='https://www.rhoxcoin.com/'>https://www.rhoxcoin.com/</a> and any sub-domains of this site, unless expressly excluded by their own terms and conditions.
</p>
<h1>2. Acceptance of Terms</h1>
<p>2.1. Your agreement to comply with and be bound by these Terms and Conditions is deemed to occur upon your first use of the Website. Your access to and use of the Website and/or Services is subject (unless expressly stated otherwise) exclusively to these Terms and Conditions. You will not use the Website for any purpose that is unlawful or prohibited by these Terms and Conditions or by applicable law. By using the Website you are fully accepting the terms, conditions and disclaimers contained in these Terms and Conditions. If you do not agree to be bound by these Terms and Conditions, you should stop using the Website, the community access and/or the Services immediately.<br/><br/>
2.2.
RHOX NFT reserves the right to change these Terms and Conditions without notice, and Users agree to be bound by such changes. It is the responsibility of Users to check these Terms and Conditions regularly for any changes.<br/><br/>
2.3.
If you are entering into these Terms and Conditions on behalf of your employer or a third party or acting as an employee, you warrant that you are authorised to enter into legally binding contracts on behalf of your employer or the third party. You further warrant that your employer or third party agrees to be bound by these Terms and Conditions.
</p>
<h1>3. Intellectual Property and Acceptable Use of Content</h1>
<p>3.1.
All Content included on the Website, unless uploaded by Service Providers or as stated otherwise, is the property of RHOX NFT, our affiliates, licensees or other relevant third parties. By continuing to use the Website Users acknowledge that such Content is protected by copyright, trademarks, database rights and other intellectual property rights. Nothing on this site shall be construed as granting, by implication, estoppel, or otherwise, any licence or right to use any trademark, logo or service mark displayed on the site without the owner's prior written permission.<br/><br/>
3.2.
Subject to Clause 4, RHOX NFT hereby grants to the User a non-exclusive licence for the duration of the ownership of the NFT or in the case of a User who is not a Member, until RHOX NFT may choose to revoke such licence, to download, store, use, reproduce, transmit, display, copy and provide access to Content in accordance with the terms of the community membership (where applicable) and at all times only for the User's own information purposes and for the avoidance of doubt, not for any commercial or business purposes.<br/><br/>
3.3.
Without prejudice to any rights granted herein, RHOX NFT reserves the right to amend, edit or abbreviate or take down any Content at its discretion.<br/><br/>
3.4.
The names, images and logos identifying RHOX NFT or third parties, including the Service Providers, and their products and services are subject to copyright, design rights and trade marks of RHOX NFT and/or the relevant third parties. Nothing contained in these terms shall be construed as conferring any licence or right to use any trade mark, design right, name or copyright of RHOX NFT or any other third party.
</p>
<h1>4. Prohibited Use of the Site by Users</h1>
<p>Users are expressly forbidden to download, store, reproduce, transmit, display (including without limitation via an intranet or extranet site), copy, sell publish, distribute, provide access to Content for any purposes other than as set out herein or to sub-licence, rent, lease, transfer or assign any rights in Content, to any other person, commercially exploit or use Content for any unlawful purpose.</p>
<h1>5. Availability of the Website and Disclaimers</h1>
<p>5.1.
The Website and Services are provided "as is" and on an "as available" basis. RHOX NFT gives no warranty that the Website or Services will be free of defects and/or faults. To the maximum extent permitted by law, RHOX NFT provides no warranties (express or implied) of fitness for a particular purpose, accuracy of information, compatibility and satisfactory quality. RHOX NFT is under no obligation to update information on the Website.<br/><br/>
5.2.
Whilst RHOX NFT uses reasonable endeavours to ensure that the Website is secure and free of errors, viruses and other malware, RHOX NFT gives no warranty or guarantee in that regard and all Users take responsibility for their own security, that of their personal details and their computers.<br/><br/>
5.3.
RHOX NFT accepts no liability for any disruption or non-availability of the Website.<br/><br/>

5.4.
RHOX NFT reserves the right to alter, suspend or discontinue any part (or the whole of) the Website including, but not limited to, any products and/or services available. These Terms and Conditions shall continue to apply to any modified version of the Website unless it is expressly stated otherwise.<br/><br/>
5.5.
Buyer hereby acknowledge and accept that any involvement with the RHOX community does not guarantee access to any individual Services or the Services at any given time or day.  In particular, in addition to compliance with Clause 9.8, the community may have to pre-book certain Services in advance in accordance with the process set out for that particular Service.
</p>
<h1>6. Limitation of Liability</h1>
<p>6.1.
RHOX NFT will not be liable to Users in respect of any losses arising out of events beyond RHOX NFT reasonable control.<br/><br/>
6.2.
To the maximum extent permitted by law, RHOX NFT accepts no liability for any of the following:<br/><br/>
6.2.1. any business losses, such as loss of profits, income, revenue, anticipated savings, business, contracts, goodwill or commercial opportunities;<br/><br/>
6.2.2. loss or corruption of any data, database or software;<br/><br/>
6.2.3. any special, indirect or consequential loss or damage; or<br/><br/>
6.2.4. losses suffered by third parties,
as a result of the use by Users of the Website or the Services.
</p>
<h1>7. Website Content and Services</h1>
<p>
7.1.
Except as otherwise expressly agreed by RHOX NFT in writing, information regarding RHOX NFT community and Services is subject to change without notice.<br/><br/>
7.2.
Information about RHOX NFT community and Services made available on and/or through this Website shall not constitute a representation, warranty or other commitment by RHOX NFT with respect to any product or service unless otherwise expressly agreed to by RHOX NFT in writing.<br/><br/>
7.3.
Without limiting the generality of the foregoing, RHOX NFT hereby disclaims all warranties, express or implied, as to accuracy, suitability for any purpose or completeness of any Content or Services.
</p>
<h1>8. Third Party Content</h1>
<p>8.1.
This Website makes available certain Content that has not been created by RHOX NFT either via hyperlinks which may take Users to websites not controlled or maintained by RHOX NFT, or as hosted via the Website whether or not such Content is available free of charge to all Users, to Members only or for a fee ("Third Party Content").<br/><br/>
8.2.
Any copying, distribution, reproduction, retransmission, or modification of any of the Third Party Content or any information or materials appearing in such Third Party Content, whether in electronic or hard copy form, is subject to the prior written permission of the author and publisher of such Third Party Content.<br/><br/>
8.3.
Third Party Content is not the responsibility of RHOX NFT, and Users acknowledge and confirm that RHOX NFT has no control over the opinions, information, legality of products, or accuracy of facts or statements contained in such Third Party Content and furthermore RHOX NFT cannot guarantee and makes no representation or warranty as to the accuracy, veracity, or completeness of any such information including but not limited to information relating to the Services. Nothing contained within Third Party Content necessarily represents the point of view of RHOX NFT.<br/><br/>
8.4.
Downloading some Third Party Content may involve RHOX NFT sharing personal data collected during registration with the authors of such Third Party Content. Users who wish to download such Third Party Content should refer to our Privacy Policy and Clause 15.1 (Data Protection) of these Terms and Conditions prior to downloading. Users who do not wish to have their data shared in this manner should not download such Third Party Content.
</p>
<h1>9. Buying process from website</h1>
<p>9.1</p>
<h1>10. Transfer of NFT</h1>
<p>10.1</p>
<h1>11. Damaged or Stolen items</h1>
<p>11.1.
If a Member becomes aware that an item of clothing is lost or stolen, or that it has been used in any unauthorised way, then such buyer shall promptly notify RHOX NFT of this.<br/><br/>
11.2.
If apparel has been reported lost or stolen, RHOX NFT shall issue a replacement item upon review and issue of police report. Subject to Clause 14.4, item will be sent by RHOX NFT within 3 Business Days of the date of the report of the lost or stolen item. <br/><br/>
11.3.
Lost or stolen items shall be registered with police reference number within the UAE.
</p>
<h1>12. Pricing</h1>
<p>12.1. 
The description of any items, purchased hereunder will be confirmed in RHOX NFT' dispatch note and/or invoice or at point of payment if purchased online.<br/><br/>
12.2.
Prices for any RHOX NFT Services or Membership Cards are subject to change without prior notice. 
</p>
<h1>13. Payment of goods & services</h1>
<p>13.1.
The Apparel must be paid in advance of a items being issued and the Services being used.<br/><br/>
13.2.
Membership Fee(s) are payable in AED and may be paid by credit card. 
</p>
<h1>14. Product Delivery</h1>
<p>14.1.
In order for RHOX NFT to deliver, each Member shall provide the following details to RHOX NFT in relation to the Member (as well as such other information RHOX NFT shall request of Members from time to time):<br/><br/>
14.2.1. name;<br/><br/>
14.2.2. current residential address;<br/><br/>
14.2.3. date of birth;<br/><br/>
14.2.4. email address;<br/><br/>
14.2.5. mobile number; <br/><br/>
14.2.
Supply is delivered by courier service unless otherwise agreed by RHOX NFT. <br/><br/>
14.4.
RHOX NFT cannot guarantee the availability of apparel at any given time and some items may therefore take longer to dispatch.
</p>
<h1>15. General</h1>
<p>15.1. Data Protection<br/><br/>
15.1.1. The User (which term shall include Members) acknowledges and consents that, subject to RHOX NFT complying with all applicable data protection laws in force from time to time, RHOX NFT may disclose to relevant third parties (including but not limited to the Service Providers) any personal information relating to the User as is reasonably necessary in order to, amongst other things, process applications for Membership, perform RHOX NFT ' obligations under these Terms and Conditions and allow the Service Providers to provide the Services.<br/><br/>
15.1.2. The User acknowledges and consents to RHOX NFT using personal information about Users for market research purposes and to inform Users RHOX NFT products and services, legal developments or information which RHOX NFT believes may be of interest to Users. For further details please consult our Privacy Policy.<br/><br/>

15.2. Force Majeure<br/><br/>
If by reason of labour dispute, strikes, inability to obtain labour or materials, fire or other action of the elements, accidents, power or telecommunications failure, customs delays, governmental restrictions or appropriation or other causes beyond the control of a party, such that RHOX NFT is unable to perform in whole or in part its obligations set forth in these terms, then RHOX NFT shall be relieved of those obligations to the extent it is unable to perform them and such inability to perform shall not make RHOX NFT liable to any other party.<br/><br/>
15.3. Nature of Agreement<br/><br/>
15.3.1. For the avoidance of doubt it is hereby expressly agreed and declared that:<br/><br/>
15.3.1.1. nothing in these Terms and Conditions shall create or form or be deemed to create or form a partnership or joint venture or establish a relationship of employer and employee between the Parties; and<br/><br/>
15.3.1.2. neither Party shall be considered a Commercial Agent of the other Party for the purposes of UAE Law No. 18 of 1981, as amended (the "Commercial Agencies Law") and these Terms and Conditions shall not be registered as a Commercial Agency in accordance with the provisions of the Commercial Agencies Law; and<br/><br/>
15.3.2. nothing contained in these Terms and Conditions shall impose upon either Party an obligation to refund to the other Party any costs or expenses incurred or disbursements made by the other Party in the performance of its obligations hereunder unless the Parties agree in writing in advance that such costs, expenses or disbursements will be so refunded.<br/><br/>
15.4. Miscellaneous<br/><br/>
15.4.1 RHOX NFT may transfer their rights under these Terms and Conditions where RHOX NFT reasonably believes Users rights will not be affected.<br/><br/>
15.4.2. These Terms and Conditions may be varied by RHOX NFT from time to time. Such revised terms will apply from the date of publication. Users should check the Terms and Conditions regularly to ensure familiarity with the then current version. <br/><br/>
15.4.3. These Terms and Conditions contain the whole agreement between the parties relating to its subject matter and supersede all prior discussions, arrangements or agreements that might have taken place in relation to the Terms and Conditions.<br/><br/>
15.4.4. The illegality and invalidity of any part of these Terms and Conditions shall not prejudice the enforceability of any other part which shall be severable from this Agreement.<br/><br/>
15.4.5. The failure or delay on the part of either Party to exercise or enforce any right conferred upon it by these Terms and Conditions shall not be deemed to be a waiver of any such right or operate so as to bar the exercise or enforcement of that right at any time.<br/><br/>
15.5. Governing Law and Jurisdiction<br/><br/>
15.5.1. These Terms and Conditions, any non-contractual obligations arising out of or in connection with them and the arbitration agreement set forth in this Clause, will be governed by and construed in accordance with DIFC law, Dubai, UAE. <br/><br/>
15.5.2. Any dispute (including non-contractual obligations) arising out of or in connection with these Terms and Conditions including, without limitation, any dispute regarding the existence, formation, performance, interpretation, nullification, termination or invalidation of these Terms and Conditions, shall be referred to and finally resolved by arbitration in accordance with the Arbitration Rules of the DIFC-LCIA from time to time in force (the "Rules"), which Rules are deemed to be incorporated by reference into this Clause. For the purposes of any arbitration proceedings commenced pursuant to this Clause:<br/><br/>
15.5.2.1. the arbitration shall be conducted by a sole arbitrator to be agreed between the Parties or, in default of agreement, appointed by the President of the DIFC-LCIA Arbitration Centre or any person to whom the said President has from time to time delegated his power to make such appointments;<br/><br/>
15.5.2.2. Article 6 of the Rules shall not apply and accordingly, the appointed sole arbitrator may be of the same nationality as any of the Parties;<br/><br/>
15.5.2.3. the seat (or legal place) of arbitration shall be the DIFC in the UAE;<br/><br/>
15.5.2.4. the place at which the arbitration takes place shall be Dubai, UAE and <br/><br/>
17.5.2.5. the language to be used in the arbitral proceedings shall be English.<br/><br/>
15.5.3. Each of the Parties:<br/><br/>
15.5.3.1. will submit to the jurisdiction of any court of the DIFC for the ratification of any award made pursuant to this Clause;<br/><br/>
15.5.3.2. will not challenge any arbitral award made pursuant to arbitration proceedings conducted in accordance with this Clause; and <br/><br/>
15.5.3.3. will not object to or challenge any application to enforce any arbitral award made pursuant to arbitration proceedings conducted in accordance with this Clause in any court and it will submit to the jurisdiction of that court for the purposes of those enforcement proceedings.
</p>
        </div>
    </div>
  )
}

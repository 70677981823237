import React from 'react'
import './style.css'
import { AiFillInstagram, AiOutlineTwitter } from 'react-icons/ai'
import { FaDiscord, FaFacebookF, FaTelegramPlane } from 'react-icons/fa'

export default function Social() {
    return (
        <div className='Social'>
            <div className='SocialTextCon'>
                <h2>ON SOCIAL MEDIA</h2>
                <div className='SocialTextCon1'/>
                <h4>CONNECT WITH US</h4>
            </div>
            <div className='SocialIconCon'>
                <a className='SocialIcon' target={"_blank"} href="https://x.com/rhoxcoin">
                    <AiOutlineTwitter color='#000' size={25} />
                </a>
                {/* <a className='SocialIcon' target={"_blank"} href=" https://dsc.gg/evermore">
                    <FaDiscord color='#fff' size={25} />
                </a> */}
                <a className='SocialIcon' target={"_blank"} href="https://www.instagram.com/riot.funk/">
                    <AiFillInstagram color='#000' size={25} />
                </a>
                {/* <a className='SocialIcon'>
                    <FaFacebookF color='#fff' size={20} />
                </a> */}
                <a className='SocialIcon' target='_blank' href='https://t.me/rhoxcoin'>
                    <FaTelegramPlane color='#000' size={20} />
                </a>
            </div>
            <div className='SocialAddressCon'>
                <h4>Wallet Address</h4>
                <button onClick={()=>navigator.clipboard.writeText('Wallet Address')}>Copy</button>
            </div>
        </div>
    )
}

import React, { useEffect, useState } from "react";
import { GoPlus, GoDash } from "react-icons/go";
import "./style.css"

export default function FAQ({ myRef }) {
  const datas = [
      {
        title:
          "What are the RHOX NFT rarity levels?",
        content: `Royals 1/1 NFT & Apparel, Legends 3/3 NFT & Apparel, Elite 10/10 NFT & Apparel.`,
      },
      {
        title: "What designs are we expecting on the upcoming drops?",
        content: `Some drops will be themed e.g. (Ancient Gods, Cyber Punk or Minimalistic) and some drop will be set of designs from a single artist as we believe in creating opportunities for upcoming and underground artist.`,
      },
      {
        title:
          "How many drops will RHOX have?",
        content: `We don’t plan to stop and expect a monthly drop and maybe a bi-monthly drop in 2023.`,
      },
      {
        title:
        "Do I get a lifetime supply of apparel?",
        content: `Yes, you can wear your apparel for “RHOX”, to protect this concept from abuse, we have limited the supply of 3 apparels per year for the Royals, 2 for the Legends and 1 per year for the Elite. You can claim your free replacement only if the apparel is damaged beyond repair, require re-sizing or the NFC tag is no longer working. You will be required to ship us your current apparel, we will then destroy it and ship you the new apparel, this ensures that no one else is wearing the apparel only the NFT holder which is you!`,
      },
      {
        title:
          "How long does shipping my apparel take?",
        content: `Please allow up to 40 days.`,
      },
      {
        title:
          "Can I sell my NFT?",
        content: `Yes, you own the NFT, but remember only one person can wear and own the NFT at the same time. We only ship you the apparel if you have staked the NFT. To un-stake your NFT, you will have to ship us the apparel…then you will be able to sell it.`,
      },
      {
        title:
          "Can I sell the apparel without the NFT?",
        content: `It is not recommended, we need to ensure that only the holder of the NFT can wear the apparel, you will also need to send us the apparel to un stake the NFT…However, you can sell/flip the NFT if you haven’t requested the apparel to be shipped yet.`,
      },
    ]

  const[index, setIndex] = useState(null)
    const onOpen=(val)=>{
        if(index == val){
            setIndex(null)
        }else{
            setIndex(val)
        }
    }

  return (
    <div ref={myRef} className={"FAQ"}>
      <div className='FAQCon'>
                <h1>FREQUENTLY ASKED QUESTIONS</h1>
                <div className='FAQComCon'>
                    <div className='FAQCom'>
                    <div className='FAQQcon' onClick={()=> onOpen(0)}>
                        <p>{data[0].title}</p>
                        <div className='PlusCon'>
                            {index == 0?
                            <GoDash size={22} color='#000'/>
                            :
                            <GoPlus size={22} color='#000'/>
                            }
                        </div>
                    </div>
                    <div className={ index == 0? 'FAQAnsCon FAQOpen' : 'FAQAnsCon'}>
                    <h2>{data[0].content}</h2>
                    </div>
                    </div>
                    <div className="FAQSep"/>
                    <div className='FAQCom'>
                    <div className='FAQQcon' onClick={()=> onOpen(1)}>
                        <p>{data[1].title}</p>
                        <div className='PlusCon'>
                            {index == 1?
                            <GoDash size={22} color='#000'/>
                            :
                            <GoPlus size={22} color='#000'/>
                            }
                        </div>
                    </div>
                    <div className={ index == 1? 'FAQAnsCon FAQOpen' : 'FAQAnsCon'}>
                    <h2>{data[1].content}</h2>
                    </div>
                    </div>
                </div>
                <div className='FAQComCon'>
                <div className='FAQCom'>
                    <div className='FAQQcon' onClick={()=> onOpen(2)}>
                        <p>{data[2].title}</p>
                        <div className='PlusCon'>
                            {index == 2?
                            <GoDash size={22} color='#000'/>
                            :
                            <GoPlus size={22} color='#000'/>
                            }
                        </div>
                    </div>
                    <div className={ index == 2? 'FAQAnsCon FAQOpen' : 'FAQAnsCon'}>
                    <h2>
                    {data[2].content}
                    </h2>
                    </div>
                    </div>
                    <div className="FAQSep"/>
                    <div className='FAQCom'>
                    <div className='FAQQcon' onClick={()=> onOpen(3)}>
                        <p>{data[3].title}</p>
                        <div className='PlusCon'>
                            {index == 3?
                            <GoDash size={22} color='#000'/>
                            :
                            <GoPlus size={22} color='#000'/>
                            }
                        </div>
                    </div>
                    <div className={ index == 3? 'FAQAnsCon FAQOpen' : 'FAQAnsCon'}>
                    <h2>
                    {data[3].content}
                    </h2>
                    </div>
                    </div>
                    </div>
                    <div className='FAQComCon'>
                <div className='FAQCom'>
                    <div className='FAQQcon' onClick={()=> onOpen(4)}>
                        <p>{data[4].title}</p>
                        <div className='PlusCon'>
                            {index == 4?
                            <GoDash size={22} color='#000'/>
                            :
                            <GoPlus size={22} color='#000'/>
                            }
                        </div>
                    </div>
                    <div className={ index == 4? 'FAQAnsCon FAQOpen' : 'FAQAnsCon'}>
                    <h2>
                    {data[4].content}
                    </h2>
                    </div>
                    </div>
                    <div className="FAQSep"/>
                    <div className='FAQCom'>
                    <div className='FAQQcon' onClick={()=> onOpen(5)}>
                        <p>{data[5].title}</p>
                        <div className='PlusCon'>
                            {index == 5?
                            <GoDash size={22} color='#000'/>
                            :
                            <GoPlus size={22} color='#000'/>
                            }
                        </div>
                    </div>
                    <div className={ index == 5? 'FAQAnsCon FAQOpen' : 'FAQAnsCon'}>
                    <h2>
                    {data[5].content}
                    </h2>
                    </div>
                    </div>
                    </div>
                    <div className='FAQComCon'>
                <div className='FAQCom'>
                    <div className='FAQQcon' onClick={()=> onOpen(6)}>
                        <p>{data[6].title}</p>
                        <div className='PlusCon'>
                            {index == 6?
                            <GoDash size={22} color='#000'/>
                            :
                            <GoPlus size={22} color='#000'/>
                            }
                        </div>
                    </div>
                    <div className={ index == 6? 'FAQAnsCon FAQOpen' : 'FAQAnsCon'}>
                    <h2>
                    {data[6].content}
                    </h2>
                    </div>
                    </div>
                    <div className="FAQSep"/>
                    <div className='FAQCom'>
                    <div className='FAQQcon' onClick={()=> onOpen(7)}>
                        <p>{data[7].title}</p>
                        <div className='PlusCon'>
                            {index == 7?
                            <GoDash size={22} color='#000'/>
                            :
                            <GoPlus size={22} color='#000'/>
                            }
                        </div>
                    </div>
                    <div className={ index == 7? 'FAQAnsCon FAQOpen' : 'FAQAnsCon'}>
                    <h2>
                    {data[7].content}
                    </h2>
                    </div>
                    </div>
                    </div>
                    <div className='FAQComCon'>
                <div className='FAQCom'>
                    <div className='FAQQcon' onClick={()=> onOpen(8)}>
                        <p>{data[8].title}</p>
                        <div className='PlusCon'>
                            {index == 8?
                            <GoDash size={22} color='#000'/>
                            :
                            <GoPlus size={22} color='#000'/>
                            }
                        </div>
                    </div>
                    <div className={ index == 8? 'FAQAnsCon FAQOpen' : 'FAQAnsCon'}>
                    <h2>
                    {data[8].content}
                    </h2>
                    </div>
                    </div>
                    <div className="FAQSep"/>
                    <div className='FAQCom'>
                    <div className='FAQQcon' onClick={()=> onOpen(9)}>
                        <p>{data[9].title}</p>
                        <div className='PlusCon'>
                            {index == 9?
                            <GoDash size={22} color='#000'/>
                            :
                            <GoPlus size={22} color='#000'/>
                            }
                        </div>
                    </div>
                    <div className={ index == 9? 'FAQAnsCon FAQOpen' : 'FAQAnsCon'}>
                    <h2>
                    {data[9].content}
                    </h2>
                    </div>
                    </div>
                    </div>
                    <div className='FAQComCon'>
                <div className='FAQCom'>
                    <div className='FAQQcon' onClick={()=> onOpen(10)}>
                        <p>{data[10].title}</p>
                        <div className='PlusCon'>
                            {index == 10?
                            <GoDash size={22} color='#000'/>
                            :
                            <GoPlus size={22} color='#000'/>
                            }
                        </div>
                    </div>
                    <div className={ index == 10? 'FAQAnsCon FAQOpen' : 'FAQAnsCon'}>
                    <h2>
                    {data[10].content}
                    </h2>
                    </div>
                    </div>
                    <div className="FAQSep"/>
                    <div className='FAQCom'>
                    <div className='FAQQcon' onClick={()=> onOpen(11)}>
                        <p>{data[11].title}</p>
                        <div className='PlusCon'>
                            {index == 11?
                            <GoDash size={22} color='#000'/>
                            :
                            <GoPlus size={22} color='#000'/>
                            }
                        </div>
                    </div>
                    <div className={ index == 11? 'FAQAnsCon FAQOpen' : 'FAQAnsCon'}>
                    <h2>
                    {data[11].content}
                    </h2>
                    </div>
                    </div>
                    </div>
                    <div className='FAQComCon'>
                <div className='FAQCom'>
                    <div className='FAQQcon' onClick={()=> onOpen(12)}>
                        <p>{data[12].title}</p>
                        <div className='PlusCon'>
                            {index == 12?
                            <GoDash size={22} color='#000'/>
                            :
                            <GoPlus size={22} color='#000'/>
                            }
                        </div>
                    </div>
                    <div className={ index == 12? 'FAQAnsCon FAQOpen' : 'FAQAnsCon'}>
                    <h2>
                    {data[12].content}
                    </h2>
                    </div>
                    </div>
                    <div className="FAQSep"/>
                    <div className='FAQCom'>
                    <div className='FAQQcon' onClick={()=> onOpen(13)}>
                        <p>{data[13].title}</p>
                        <div className='PlusCon'>
                            {index == 13?
                            <GoDash size={22} color='#000'/>
                            :
                            <GoPlus size={22} color='#000'/>
                            }
                        </div>
                    </div>
                    <div className={ index == 13? 'FAQAnsCon FAQOpen' : 'FAQAnsCon'}>
                    <h2>
                    {data[13].content}
                    </h2>
                    </div>
                    </div>
                    </div>
            </div>
    </div>
  );
}

const data =[
  {
    title:"What is an NFT?",
    content: "Non-fungible tokens (NFTs) are cryptographic assets on a blockchain with unique identification codes and metadata that distinguish them from each other. Ownership and transfer of an NFT is secured by a blockchain, which ensures that one NFT only has one owner at any given time, and that nobody can modify the history of the token’s ownership."
  },
  {
    title:"What is cryptocurrency?",
    content: "Cryptocurrency is a digital currency that uses the blockchain (a digital transaction ledger) to record and secure online transactions. Cryptocurrencies can be used to buy goods and services. "
  },
  {
    title:"What is a “digital wallet”?",
    content: "A digital wallet is an online storage that enables you to store and transact digital currencies and Non Fungible tokens. With a digital wallet, you hold the keys and are in charge of your own assets stored in that wallet. Your assets are safe as long as you secure your private keys. Remember to never, ever share your seed phrase to anyone. Example for digital wallets are Metamask, Trust wallet etc."
  },
  {
    title:"What blockchain are these NFTs minted on? ",
    content: "This NFT is minted on the Ethereum blockchain"
  },
  {
    title:"What is Gas/Gwei or minting fees?",
    content: "Gas fees or minting fees are a transaction fee on the Ethereum blockchain. When you make transactions, such as purchasing an NFT, you will need enough ETH in your wallet for the initial purchase and the associated gas fees. The gas fee enables your transaction to be logged on the Ethereum network. Gas fees are taken by Ethereum. Lost gas fees are non-refundable. Move forward at your own risk."
  },
  {
    title:"How do I purchase ETH (Ethereum)?",
    content: "You can purchase ETH through an online exchange such as Binance. Once you have purchased ETH, you can transfer the funds into your Metamask or another compatible wallet to purchase an NFT. "
  },
  {
    title:"How do I buy RHOX NFTs?",
    content:"RHOX NFTs can be purchased through RHOX website during the NFT drops or through secondary markets like Opensea."
  },
  {
    title:"How can I assure a guaranteed spot for an NFT?",
    content: "Prior to each drop, we open our registration process in which you can apply to get a whitelist. If you are already an EVERCLUB member you will get notified of our upcoming drops."
  },
  {
    title:"How do I become an EVERCLUB member?",
    content:"After buying an RHOX NFT (either directly through us or secondary markets), and when you register for a lifetime apparel from us, you will be automatically enrolled in the Everclub membership. At the time of registration, you will be required to stake the RHOX NFT. Please note that the rewards will be transferred to the wallet address that you stake."
  },
  {
    title: "How can I earn rewards?",
    content: "After becoming an Everclub member, the apparel will be shipped to your shipping address. Once you receive the apparel, download the RHOX app and register your apparel in the app with your wallet address that you registered for EVERCLUB membership. Voila, when you are on the move, the rewards will start building up!"
  },
  {
    title: "Do I get a lifetime supply of apparel?",
    content: "Yes, you can. Inorder to protect this concept from abuse, we have limited the supply of 3 apparels per year for the Royals, 2 for the Legends and 1 per year for the Elite. You can claim your free replacement only if the apparel is damaged beyond repair, requires re-sizing or if  the NFC tag is no longer working. You will be required to ship your current apparel back to us and after confirming the claim, we will be shipping you brand new apparel with your unique NFT metadata."
  },
  {
    title: "How can I sell my NFT?",
    content: "You can trade your RHOX NFTs through secondary markets like Opensea."
  },
  {
    title: "I have RHOX apparel, can I sell my RHOX NFT?",
    content: "Owning an RHOX apparel means that you have staked your NFT in our website which lets you earn rewards through our membership. If you decide to sell the NFT, you will need to unstake the NFT which requires the RHOX apparel to be shipped back to us. Once we receive the apparel, you will be receiving a unique code which lets you unstake the NFT."
  },
  {
    title: "I have not ordered any apparel, can I sell my NFT?",
    content:"If you have not ordered any apparel, you are free to trade your NFTs."
  }

]
import React from 'react'

export default function Privacy() {
  return (
    <div className='Privacy'>
        <div className='PrivacyCon'>
        <h1>Privacy Policy</h1>
        <p>We are committed to endeavouring to manage any personal information always provided to us in a responsible and secure manner and in accordance with applicable laws. By choosing to provide us with your personal information, you are consenting to its use by us in accordance with these Terms and Conditions. We shall not share your personal information with third parties or use it for any substantially or materially different purpose unless otherwise stated at the time of collection. We may contact you for the purposes of updating you on our products and services as well as any other information that we believe may be of interest to you. In the event you do not wish to be contacted for marketing purposes, please notify us at the time you are asked to provide us with any personal information.<br/><br/>
        You expressly acknowledge that we may use your anonymous data in order to collate industry and marketing statistics. We may also ask third parties to collate any such statistics on our behalf.
        </p>
        <h1>Cookies</h1>
        <p>Cookies are pieces of information that are stored on your computer and which allow us to recognize you as a user of this Website and monitor which parts of the Website you have visited. No secure information or personal data is contained in our cookies.</p>
        <h1>Amendment</h1>
        <p>These Terms and Conditions are subject to change and can be modified at any time without notice. Your continued use of the Website following the posting of changes to these Terms and Conditions further signifies that you accept such changes. No variation to these Terms & Conditions shall be of any effect unless made in writing by and with the authority of RHOX NFT.</p>
        <h1>User Accounts</h1>
        <p>You may need to create an account and provide information about yourself to use some of the features on the Website. You are responsible for maintaining the confidentiality of your account password. You are also responsible for all activities that occur in connection with your account. You agree to notify us immediately of any unauthorized use of your account.
        Your account is for your personal, non-commercial use only. You may not impersonate someone else (e.g., adopt the identity of a celebrity), create an account for anyone other than yourself, provide an email address other than your own, or create multiple accounts.  We reserve the right to close your account at any time for any or no reason.
        </p>
        <h1>Restrictions on Use</h1>
        <h2>You agree that you will not, and will not assist or enable others to:</h2>
        <p>
        Use the Website to threaten, stalk, defraud, incite, defame, harass, or advocate the harassment of another person, or otherwise interfere with another user's use of the Website;<br/><br/>
Use the Website to submit or transmit spam, chain letters, contests, junk email, pyramid schemes, surveys, or other mass messaging, whether commercial in nature or not;<br/><br/>
Use the Website in a manner that may create a conflict of interest, such as trading reviews with other business owners or writing or soliciting reviews;<br/><br/>
Use the Website to promote bigotry or discrimination against protected classes;<br/><br/>
Use the Website to violate any third-party right, including any breach of confidence, copyright, trademark, patent, trade secret, moral right, privacy right, right of publicity, or any other intellectual property or proprietary right;<br/><br/>
Use the Website to submit or transmit pornography or illegal content;<br/><br/>
Use the Website to solicit personal information from minors or to harm or threaten to cause harm to minors;<br/><br/>
Use any robot, spider, site search/retrieval application, or other automated device, process or means to access, retrieve, scrape, or index the Website or any Website content;<br/><br/>
Attempt to gain unauthorized access to the Website, user accounts, computer systems or networks connected to the Website through hacking, password mining or any other means; use the Website or any Website content to transmit any computer viruses, worms, defects, Trojan horses or other items of a destructive nature (collectively, "Viruses"); use any device, software or routine that interferes with the proper working of the Website, or otherwise attempt to interfere with the proper working of the Website; make excessive traffic demands; use the Website to violate the security of any computer network, crack passwords or security encryption codes; disrupt or interfere with the security of, or otherwise cause harm to, the Website or Website content; remove, circumvent, disable, damage or otherwise interfere with any security-related features of the Website, features that prevent or restrict the use or copying of Website content, or features that enforce limitations on the use of the Website.
        </p>
        <h1>Product Validity, Modifications & Amendments</h1>
        <p>All products and services offered by RHOX NFT are valid as per the dates displayed at the Website or any partner website. RHOX NFT is not responsible or liable for any information that it does not directly provide.  All requests for modifications/amendments must be directed to the RHOX NFT Customer Care Team who will process the request. If you request a date change for delivery, RHOX NFT will attempt to honour your request; however changes may attract an additional fee.<br/><br/>
All other services change requests and amendments are subject to availability and/or service providers; RHOX NFT cannot guarantee the success of any date-change request.<br/><br/>
If you make a purchase using a RHOX NFT promotion code, or if you accept a discount or special-offer price, you may invalidate your discount or special-offer price by making any changes or amendments to your itinerary once your purchase is complete. RHOX NFT may decide, at its sole discretion, to honour your discount or special-offer pricing if you make a post-purchase change or amendment to your itinerary. However, RHOX NFT reserves the right to invalidate your discount or special-offer pricing if you make any changes to your confirmed itinerary.<br/><br/>
Occasionally our suppliers and/or service providers make changes to tour dates, prices, inclusions, coverage, age requirements, etc. As a result, RHOX NFT reserves the right to cancel, change or substitute any service or product that you have booked via the Website or through partner websites, at any time, for any reason.<br/><br/>
Notwithstanding the above, when we are informed in advance by our agents, service providers and/or suppliers of a significant change to an order, we make every reasonable effort to notify the consumer and/or distributor as appropriate, to amend or re-issue the order where feasible.
</p>
</div>
</div>
  )
}

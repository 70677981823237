import React from 'react'
import FootImg from "../assets/1gif (1).gif";
import Logo from "../assets/footLogo.png";
import { AiOutlineTwitter, AiFillInstagram } from "react-icons/ai";
import { FaDiscord, FaFacebookF, FaTelegramPlane } from "react-icons/fa";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div className='Footer'>
        <span>
        <div className='FootImg1'/>
        <div className='FootCon'>
            <img className='FootImg2' src={Logo}/>
            <div className='SocialCon'>
            <a className='SocialIcon' target={"_blank"} href="https://x.com/rhoxcoin">
                    <AiOutlineTwitter color='#000' size={25}/>
                </a>
                {/* <a className='SocialIcon' target={"_blank"} href=" https://dsc.gg/evermore">
                    <FaDiscord color='#000' size={25}/>
                </a> */}
                <a className='SocialIcon' target={"_blank"} href="https://www.instagram.com/riot.funk/">
                    <AiFillInstagram color='#000' size={25}/>
                </a>
                {/* <a className='SocialIcon'>
                    <FaFacebookF color='#000' size={20}/>
                </a> */}
                <a className='SocialIcon' target='_blank' href='https://t.me/rhoxcoin'>
                    <FaTelegramPlane color='#000' size={20}/>
                </a>
            </div>
            <p>© 2024 All rights reserved</p>
        </div>
        <ul>
            <Link style={{textDecoration:"none"}} target={"_blank"} to={"/privacy"}>
            <li>Privacy policy</li>
            </Link>
            <li>Cookies</li>
            <Link style={{textDecoration:"none"}} target={"_blank"} to={"/terms"}>
            <li>Terms & Conditions</li>
            </Link>
            <Link style={{textDecoration:"none"}} target={"_blank"} to={"/faq"}>
            <li>FAQs</li>
            </Link>
            <a style={{textDecoration:"none"}} href='mailto:info@rhoxcoin.com'>
                <li>Contact us</li>
            </a>
        </ul>
        </span>
        <div className='FootCon2'>
        <img className='FootImg2' src={Logo}/>
        <div className='SocialCon'>
                <a className='SocialIcon' target={"_blank"} href="https://x.com/rhoxcoin">
                    <AiOutlineTwitter color='#000' size={25}/>
                </a>
                {/* <a className='SocialIcon' target={"_blank"} href=" https://dsc.gg/evermore">
                    <FaDiscord color='#000' size={25}/>
                </a> */}
                <a className='SocialIcon' target={"_blank"} href="https://www.instagram.com/riot.funk/">
                    <AiFillInstagram color='#000' size={25}/>
                </a>
                {/* <a className='SocialIcon'>
                    <FaFacebookF color='#000' size={20}/>
                </a> */}
                <a className='SocialIcon' target='_blank' href='https://t.me/rhoxcoin'>
                    <FaTelegramPlane color='#000' size={20}/>
                </a>
            </div>
            <div className='FootCon'>
            <div className='FootImg1'/>
            <ul>
            <Link style={{textDecoration:"none"}} target={"_blank"} to={"/privacy"}>
            <li>Privacy policy</li>
            </Link>
            <li>Cookies</li>
            <Link style={{textDecoration:"none"}} target={"_blank"} to={"/terms"}>
            <li>Terms & Conditions</li>
            </Link>
            <Link style={{textDecoration:"none"}} target={"_blank"} to={"/faq"}>
            <li>FAQs</li>
            </Link>
            <a style={{textDecoration:"none"}} href='mailto:info@rhoxcoin.com'>
            <li>Contact us</li>
            </a>
        </ul>
        </div>
        </div>
    </div>
  )
}

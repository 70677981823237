import React from 'react'
import './style.css'
import PoweredImg from '../assets/powered.webp'
import Videos from '../assets/powered.mp4'

export default function Powered() {
  return (
    <div className='Powered'>
      <div className='PoweredCon'>
        <div className='PoweredLeft'>
          <h2>RHOX is powered by <b>riotfunk</b></h2>
          <div className='PoweredLeftCon'>
            {/* <img src={PoweredImg} /> */}
            <div className='PoweredLeftTextCon'>
              <div className='PoweredLeftTop'>
                <div className='PoweredLeftCon1'>
                  <h3>RHOX</h3>
                  <p>The Coders</p>
                  <p>The Bockchain & Crypto heads</p>
                  <p>The Meme Masters</p>
                </div>
                <div className='PoweredLeftCon1'>
                  <h3>riotfunk</h3>
                  <p>The Streetwear Experts</p>
                  <p>The Marketing Gurus</p>
                  <p>The Partnership Makers</p>
                </div>
              </div>
              {/* <h4>Join and hit the street with a RHOX vibe</h4> */}
            </div>
          </div>
        </div>
        {/* <div className='PoweredRight' /> */}
      </div>
      <div className='Powered_Bottom'/>
      <video src={Videos}  autoPlay loop muted playsInline/>
    </div>
  )
}

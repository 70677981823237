import React from 'react'
import Vid11 from "../assets/Power.mp4"

export default function Power() {
  return (
    <div className='Power'>
      <span>
        <div className='PowerCard'>
        <h1>
        Earn rewards for wearing what you love.</h1>
        <p>PoO protocol (Proof of Ownership) allows you to
        seamlessly earn and redeem $RHOX Tokens rewards
        across multiple apparels.</p>
        <p>
        Simply tap your apparel with your smart phone to
        activate PoO and earn tokens.</p>
        <p>
        Monthly apparel drops, limited supply…designed by popular NFT artists
        </p>
      </div>
      </span>
      <div className='PowerImgCon'>
        <video disablePictureInPicture src={Vid11} loop autoPlay muted playsInline />
        {/* <img src={Vid10}/> */}
      </div>
      <div className='PowerImgCon1'></div>
      <div className='PowerImgCon2'></div>
    </div>
  )
}

import React, { useState, useRef, useEffect } from 'react'
import "./style.css"
import { AiOutlineMenu, AiOutlineClose, AiFillPlayCircle } from "react-icons/ai";
import { BsPlayFill, BsPauseFill } from "react-icons/bs";
import Count from "./count";
import Rino from "../assets/ezgif.com-gif-maker (2).gif";
import { Link } from "react-router-dom";
import Audios from "../assets/audio.mp3";
import Videos from "../assets/Evermore Final (1).mp4";

export default function Hero({ getLoad }) {
  const [open, setOpen] = useState(false);
  const [time, setTime] = useState(10);
  const [play, setPlay] = useState(false);

  const myRef = useRef();

  const onAudio = () => {
    if (play) {
      setPlay(false);
      myRef.current.pause();
    } else {
      setPlay(true)
      myRef.current.play();
    }
  }

  // useEffect(()=>{
  //   if (myRef.current) {
  //     myRef.current.play();
  //   }
  // },[])

  return (
    <div className='Hero'>
      <div className='HeroDesktop'>
        <span>
          <audio ref={myRef} src={Audios} loop />
        </span>
        <video src={Videos} loop autoPlay muted playsInline onLoadedData={() => {
          setInterval(() => {
            getLoad(false);
          }, 3000)
        }} />
        <div className='VideoPlayBtn' onClick={onAudio}>
          {play ?
            <div className="playing">
              <span className="playing__bar playing__bar1"></span>
              <span className="playing__bar playing__bar2"></span>
              <span className="playing__bar playing__bar3"></span>
            </div>
            :
            <AiFillPlayCircle className='VideoPlayIcon' size={30} color='#fff' />
          }
          {play ? "Pause" : "Play"} Audio
        </div>
        <div className='HeroJukeCon' onClick={onAudio} />
      </div>
    </div>
  )
}

import React from 'react'
import utility1 from "../assets/utility1.png";
import work2 from "../assets/work2.png";
import work3 from "../assets/work3-min.png";
import work4 from "../assets/work4.png";
import work5 from "../assets/utility5.png";
import work6 from "../assets/work6.png";
import work7 from "../assets/work7.png";
import work8 from "../assets/work8.png";
import work9 from "../assets/work9.png";
import utility2 from "../assets/utility2.png";

export default function Work() {
  return (
    <div className='Work'>
      <h1>How it works</h1>
      <div className='WorkCard'>
          <img src={utility1}/>
          <div className='UtilityLight'/>
          <p>Go to “Get your NFT”</p>
      </div>
      <div className='ArrowDown'/>
      <div className='WorkCard'>
          <img src={work2}/>
          <div className='UtilityLight2'/>
          <p>Connect to your Wallet</p>
      </div>
      <div className='ArrowDown'/>
      <div className='WorkDiv'>
      <div className='ArrowLeft'/>
      <div className='WorkCard'>
          <img src={work3}/>
          <div className='UtilityLight' style={{background:"#9721BC"}}/>
          <p>Buy your digital Apparel (NFT)</p>
      </div>
      <div className='ArrowRight'/>
      </div>
      <div className='WorkDiv'>
        <div className='WorkCon'>
       
        <div className='WorkCard'>
          <img src={work4}/>
          <div className='UtilityLight'/>
          <p>Order your Wear & Earn physical<br/>apparel from get the apparel</p>
      </div>
       <span style={{borderColor:"transparent", paddingTop:0}}>
      {/* <div className='WorkCard'>
          <img src={work6}/>
          <div className='UtilityLight' style={{background:"#9721BC"}}/>
          <p>Go to Stake and stake your<br/>NFT</p>
      </div> */}
      <div className='WorkCard'>
          <img src={work7}/>
          <div className='UtilityLight2'/>
          <p>Got to shipping, select size & fill the shipping form and confirm your order</p>
      </div>
      <div className='WorkCard'>
          <img src={work8}/>
          <div className='UtilityLight' style={{background:"#9721BC"}}/>
          <p>Your apparel will be shipped!!</p>
      </div>
      <div className='WorkCard'>
          <img src={utility2}/>
          <div className='UtilityLight2' style={{background:"#9721BC"}}/>
          <p>Start Earning $RHOX</p>
      </div>
      </span>
        </div>
        <div className='WorkSeperator'/>
        <div className='WorkCon'>
        <div className='WorkCard'>
          <img src={work5}/>
          <div className='UtilityLight'/>
          <p>Trade NFT on Marketplace</p>
      </div>
      {/* <span>
      <div className='WorkCard'>
          <img src={work6}/>
          <div className='UtilityLight' style={{background:"#9721BC"}}/>
          <p>To un-stake your NFT</p>
      </div>
      <div className='WorkCard'>
          <img src={work8}/>
          <div className='UtilityLight' style={{background:"#9721BC"}}/>
          <p>Ship your physical apparel<br/>to:  Address</p>
      </div>
      <div className='WorkCard'>
          <img src={work9}/>
          <div className='UtilityLight' style={{background:"#9721BC"}}/>
          <p>Once received, we will<br/>un-stake your NFT </p>
      </div>
      </span> */}
        </div>
      </div>
    </div>
  )
}

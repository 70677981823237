import React,{useState} from 'react';
import Team1 from "../assets/1.png";
import Team2 from "../assets/2.png";
import Team3 from "../assets/3.png";
import Team4 from "../assets/4.png";
import Team5 from "../assets/5.png";
import Team6 from "../assets/6.png";
import Team7 from "../assets/7.png";
import Arrow from "../assets/slidearrow.png";

export default function Team() {
  const [slide, setSlide] = useState(0);
  const OnNext = () =>{
    if(slide == 2){
      return null
    }else{
    setSlide(slide+1);
    }
  }

  const OnPrev = () =>{
    if(slide == 0){
      return null
    }else{
    setSlide(slide-1);
    }
  }
  return (
    <div className='Team'>
      <h1>Team</h1>
        <span className='TeamCon'>
          <div className='TeamCard'>
          <div class="flip-card-inner">
          <div class="flip-card-front">
          <img src={Team1}/>
            <h2 style={{textAlign:"center"}}>SLASH</h2>
            <h3 style={{textAlign:"center"}}>Role: CEO</h3>            
          </div>
          <div class="flip-card-back">
            <h2 style={{color:"#fff"}}>SLASH</h2>
            <h3>Role: CEO</h3>
            <p>Fell into the Crypto sphere 6 years ago suffering minor injuries. Certified Blockchain Expert, worked with several crypto projects. Previously the Head of marketing in the automotive sector.</p>
          </div>
          </div>
          </div>
          <div className='TeamCard'>
          <div class="flip-card-inner">
          <div class="flip-card-front">
          <img src={Team2}/>
            <h2 style={{textAlign:"center"}}>Rkn</h2>
            <h3 style={{textAlign:"center"}}>Role: cto</h3>          
          </div>
          <div class="flip-card-back">
          <h2 style={{color:"#fff"}}>Rkn</h2>
            <h3>Role: cto</h3>
            <p>Experienced in project management, delivered many mega projects like airports, rail & infra. Founded two more Crypto projects which are currently under development. Been in the blockchain field for the last 3 years. </p>
          </div>
          </div>
          </div>
          <div className='TeamCard'>
          <div class="flip-card-inner">
          <div class="flip-card-front">
          <img src={Team3}/>
            <h2 style={{textAlign:"center"}}>STUIEP</h2>
            <h3 style={{textAlign:"center"}}>Role: cbd</h3>          
          </div>
          <div class="flip-card-back">
          <h2 style={{color:"#fff"}}>STUIEP</h2>
            <h3>Role: cbd</h3>
            <p>Been in the Crypto space for three years and Co-Founder two crypto projects. Previous life was in project management industry and loves the blood, sweat and tears of a start-up!</p>
          </div>
          </div>
          </div>
          <div className='TeamCard'>
          <div class="flip-card-inner">
          <div class="flip-card-front">
          <img src={Team4}/>
            <h2 style={{textAlign:"center"}}>CODE RUNNER</h2>
            <h3 style={{textAlign:"center"}}>Role: cbo</h3>          
          </div>
          <div class="flip-card-back">
            <h2 style={{color:"#fff"}}>CODE RUNNER</h2>
            <h3>Role: cbo</h3>
            <p>An experienced blockchain developer. Expertise includes smart contracts development, security auditing, gas-optimization, DeFi/Blockchain enabled solutions architecture and blockchain integrations.</p>
          </div>
          </div>
          </div>
          <div className='TeamCard'>
          <div class="flip-card-inner">
          <div class="flip-card-front">
          <img src={Team5}/>
            <h2 style={{textAlign:"center"}}>Kimo</h2>
            <h3 style={{textAlign:"center"}}>Role: cmo</h3>          
          </div>
          <div class="flip-card-back">
          <h2 style={{color:"#fff"}}>Kimo</h2>
          <h3>Role: cmo</h3>
          <p>A Video Producer who enjoys editing videos by day and slaying virtual bosses by night!</p>
          </div>
          </div>
          </div>
        </span>
        {/* <span className='TeamCon1'>
          <div className='TeamCard'>
          <div class="flip-card-inner">
          <div class="flip-card-front">
          <img src={Team5}/>
            <h2 style={{textAlign:"center"}}>Kimo</h2>
            <h3 style={{textAlign:"center"}}>Role: coo</h3>          
          </div>
          <div class="flip-card-back">
          <h2 style={{color:"#fff"}}>Kimo</h2>
          <h3>Role: coo</h3>
          <p>A Video Producer who enjoys editing videos by day and slaying virtual bosses by night!</p>
          </div>
          </div>
          </div>
          <div className='TeamCard'>
          <div class="flip-card-inner">
          <div class="flip-card-front">
          <img src={Team6}/>
            <h2 style={{textAlign:"center"}}>Sword</h2>
            <h3 style={{textAlign:"center"}}>Role: clo</h3>          
          </div>
          <div class="flip-card-back">
          <h2 style={{color:"#fff"}}>Sword</h2>
            <h3>Role: clo</h3>
            <p>Discovered the world of crypto 4 years ago. Commercial real estate background, managed multiple projects and assets in the retail world. Ready to change the detail in retail.</p>
          </div>
          </div>
          </div>
          <div className='TeamCard'>
          <div class="flip-card-inner">
          <div class="flip-card-front">
          <img src={Team7}/>
            <h2 style={{textAlign:"center"}}>JPG</h2>
            <h3 style={{textAlign:"center"}}>Role: Art director</h3>          
          </div>
          <div class="flip-card-back">
          <h2 style={{color:"#fff"}}>JPG</h2>
            <h3>Role: Art director</h3>
            <p>A kick-ass DJ,.. well-experienced in the region in the field of advertising. Led major re-branding projects, including creating a new brand identity for companies and designing websites by offering creative solutions. </p>
          </div>
          </div>
          </div>
        </span> */}
        <span className='TeamCon2'>
          <div className='TeamCard'>
            <img src={Team1}/>
            <h2>SLASH</h2>
            <h3>Role: CEO</h3>
            <p>Fell into the Crypto sphere 6 years ago suffering minor injuries. Certified Blockchain Expert, worked with several crypto projects. Previously the Head of marketing in the automotive sector.</p>
          </div>
          <div className='TeamCard'>
            <img src={Team2}/>
            <h2>Rkn</h2>
            <h3>Role: cto</h3>
            <p>Experienced in project management, delivered many mega projects like airports, rail & infra. Founded two more Crypto projects which are currently under development. Been in the blockchain field for the last 3 years. </p>
          </div>
          <div className='TeamCard'>
            <img src={Team3}/>
            <h2>STUIEP</h2>
            <h3>Role: cbd</h3>
            <p>Been in the Crypto space for three years and Co-Founder two crypto projects. Previous life was in project management industry and loves the blood, sweat and tears of a start-up!</p>
          </div>
          <div className='TeamCard'>
            <img src={Team4}/>
            <h2>CODE RUNNER</h2>
            <h3>Role: cbo</h3>
            <p>An experienced blockchain developer. Expertise includes smart contracts development, security auditing, gas-optimization, DeFi/Blockchain enabled solutions architecture and blockchain integrations.</p>
          </div>
          <div className='TeamCard'>
            <img src={Team5}/>
            <h2>Kimo</h2>
            <h3>Role: cmo</h3>
            <p>A Video Producer who enjoys editing videos by day and slaying virtual bosses by night!</p>
          </div>
          {/* <div className='TeamCard'>
            <img src={Team5}/>
            <h2>Kimo</h2>
            <h3>Role: coo</h3>
            <p>A Video Producer who enjoys editing videos by day and slaying virtual bosses by night!</p>
          </div>
          <div className='TeamCard'>
            <img src={Team6}/>
            <h2>Sword</h2>
            <h3>Role: clo</h3>
            <p>Discovered the world of crypto 4 years ago. Commercial real estate background, managed multiple projects and assets in the retail world. Ready to change the detail in retail.</p>
          </div>
          <div className='TeamCard'>
            <img src={Team7}/>
            <h2>JPG</h2>
            <h3>Role: Art director</h3>
            <p>A kick-ass DJ,.. well-experienced in the region in the field of advertising. Led major re-branding projects, including creating a new brand identity for companies and designing websites by offering creative solutions. </p>
          </div> */}
        </span>
        <div className='TeamSliderCon'>
          <img src={Arrow} onClick={()=>OnPrev()}/>
          <div className='SliderLineCon'>
            <div className='SliderLine' style={{width: slide == 0? "33.33333333333333%" : slide == 1? "66.66666666666667%": "100%"}}/>
          </div>
          <img src={Arrow} style={{transform:"rotate(180deg)"}} onClick={()=>OnNext()}/>
        </div>
      {/* <div class="flip-card">
      <div class="flip-card-inner">
        <div class="flip-card-front">
        
      </div>
      <div class="flip-card-back">
        <h1 style={{color:"#fff"}}>John Doe</h1>
        <p>Architect & Engineer</p>
        <p>We love that guy</p>
      </div>
    </div>
    </div> */}
    </div>
  )
}
//Team mobile full scroll
import React,{useState, useRef} from 'react'
import "./style.css"
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { BsPlayFill, BsPauseFill } from "react-icons/bs";
import Count from "./count";
import Rino from "../assets/ezgif.com-gif-maker (2).gif";
import { Link } from "react-router-dom";
import Audios from "../assets/My Drip.wav";
import Videos from "../assets/staking.mp4";

export default function Staking({getLoad}) {
  const [open, setOpen] = useState(false);
  const [time, setTime] = useState(10);
  const [play, setPlay] = useState(false);
  
  const myRef = useRef();
  
  const onAudio = () =>{
    if(play){
      setPlay(false);
      myRef.current.pause();
    }else{
      setPlay(true)
      myRef.current.play();
    }
  }
  return (
    <div className='Staking'>
      <div className='NavBar'>
        <div className='NavBar_Con'>
        <Link to={"/"} style={{textDecoration:"none"}}>
          <div className='Logo'>RHOX</div>
        </Link>
          <div className='FlexContainer'/>
          <div tabIndex={0} className='MenuCon' onClick={()=>setOpen(!open)} onBlur={(e)=>{
                  if(e.currentTarget.contains(e.relatedTarget)) return
                  else setOpen(false)
                }}>
            {open?
            <AiOutlineClose style={{marginRight:15}} size={13} color={"#000"}/>
            :
            <AiOutlineMenu style={{marginRight:15}} size={13} color={"#000"}/>
            }
            <div className={ open ?'MenuBgCon MenuShow' : 'MenuBgCon'}>
            <ul>
              <li>Connect Wallet</li>
              <li>Whitepaper</li>
              <li>Tokenomics</li>
              <Link target={"_blank"} style={{textDecoration:"none", color:"#000"}} to={"faq"}><li>FAQ</li></Link>
            </ul>
          </div>
          <div className={ open ?'MenuBgCon1 MenuShow1' : 'MenuBgCon1'}>
            <ul>
              <li>connect wallet</li>
              <Link to={"/shop"} style={{textDecoration:"none"}}>
              <li>shop.the.drop</li>
              </Link>
              <li>shipping</li>
              <Link to={"/staking"} style={{textDecoration:"none"}}>
              <li>staking</li>
              </Link>
              <li>whitepaper</li>
              <li>tokenomics</li>
              <Link target={"_blank"} style={{textDecoration:"none", color:"#000"}} to={"faq"}><li>FAQ</li></Link>
            </ul>
          </div>
          </div>
          </div>
        </div>
      <span>
        <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start"}}>
        <h1>COMING<br className='HeroTitleBreak'/> SOON</h1>
        </div>
        <div className='FlexContainer'/>
        <video src={Videos} autoPlay loop muted/>
        <img className='HeroImage' src={Rino} onLoad={()=>{
          setInterval(() => {
            getLoad(false);
        }, 3000)
          }}/>
        <div className='HeroLight1'/>
        <div className='HeroLight2'/>
      </span>
    </div>
  )
}

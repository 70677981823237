import React from 'react'
import Img1 from "../assets/modal/Shirt 001/Front.png";
import Img2 from "../assets/modal/Shirt 001/Back.png";
import Img3 from "../assets/modal/Shirt 001/Side.png";
import { IoMdClose } from "react-icons/io";
import { useNavigate } from 'react-router-dom'

export default function Modal({onClose, show}) {
    const showHideClassName = show ? "Modal display-block" : "Modal display-none";
    const navigate = useNavigate()
    return (
      <div className={showHideClassName}>
        <section className="Modal_Con">
          <div className='Modal_Img_Con'>
            <img className='Modal_Img1' src={Img1}/>
            <div className='Modal_Img_Con1'>
            <img className='Modal_Img2' src={Img2}/>
            <div style={{height:10}}/>
            <img className='Modal_Img2' src={Img3}/>
            </div>
          </div>
          <h1>The Monkey</h1>
          <p>Lorem Ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
          <h2>Requirements</h2>
          <p>There is no additional requirements</p>
          <h2>Price & Currency</h2>
          <div className='Modal_Btn_Con'>
          <select>
            <option>0.00 EVER</option>
            <option>0.00 ETH</option>
            <option>0.00 ZENIQ</option>
          </select>
          <div className='FlexCon'/>
          <button onClick={onClose}>Buy Now</button>
          <button onClick={()=>{
            onClose()
            navigate('/product')}}>View More</button>
          </div>
          <IoMdClose style={{position:"absolute", top:5, right:5, cursor:"pointer"}} size={20} color={"#fff"} onClick={onClose}/>
        </section>
      </div>
    );
  }

  //https://theflixer.tv/watch-movie/watch-lighting-up-the-stars-full-88813.9013579
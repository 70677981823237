import React,{useState, useEffect} from 'react'
import "./style.css"
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { Link } from "react-router-dom";
import Img1 from "../assets/modal/Shirt 001/Front.png";
import Img2 from "../assets/modal/Shirt 001/Back.png";
import Img3 from "../assets/modal/Shirt 001/Side.png";
import Shop from "../assets/shop.jpeg";
import Slides from './slides';

export default function Product() {
    const [open, setOpen] = useState(false);
    useEffect(() => {
      window.scrollTo(0,0)
    }, [])
    
  return (
    <div className='Product'>
        <span className='Product_Span'>
        <div className='NavBar'>
        <Link to={"/"} style={{textDecoration:"none"}}>
          <div className='Logo'>evermore</div>
        </Link>
          <div className='FlexContainer'/>
          <p className='MenuText'>shipping</p>
          <Link to={"/shop"} style={{textDecoration:"none"}}>
          <p className='MenuText'>shop.the.drop</p>
          </Link>
          <Link to={"/staking"} style={{textDecoration:"none"}}>
          <p className='MenuText'>staking</p>
          </Link>
          <div className='FlexContainer'/>
          <div tabIndex={0} className='MenuCon' onClick={()=>setOpen(!open)} onBlur={(e)=>{
                  if(e.currentTarget.contains(e.relatedTarget)) return
                  else setOpen(false)
                }}>
            {open?
            <AiOutlineClose style={{marginRight:15}} size={13} color={"#000"}/>
            :
            <AiOutlineMenu style={{marginRight:15}} size={13} color={"#000"}/>
            }
            <div className={ open ?'MenuBgCon MenuShow' : 'MenuBgCon'}>
            <ul>
              <li>Connect Wallet</li>
              <li>Whitepaper</li>
              <li>Tokenomics</li>
              <Link target={"_blank"} style={{textDecoration:"none", color:"#000"}} to={"faq"}><li>FAQ</li></Link>
            </ul>
          </div>
          <div className={ open ?'MenuBgCon1 MenuShow1' : 'MenuBgCon1'}>
            <ul>
              <li>connect wallet</li>
              <Link to={"/shop"} style={{textDecoration:"none"}}>
              <li>shop.the.drop</li>
              </Link>
              <li>shipping</li>
              <Link to={"/staking"} style={{textDecoration:"none"}}>
              <li>staking</li>
              </Link>
              <li>whitepaper</li>
              <li>tokenomics</li>
              <Link target={"_blank"} style={{textDecoration:"none", color:"#000"}} to={"faq"}><li>FAQ</li></Link>
            </ul>
          </div>
          </div>
        </div>
        </span>
        <div className='ProductCon'>
            <h4>Phygitals Royals</h4>
            <h5>Throw me Colors -Graphic Print Crew Neck T-shirt with Short Sleeves</h5>
            <div className='ProductImgCon'>
                <div className='ProductImgConLeft'>
                    <img className='ProductImg1' src={Img1}/>
                    <div className='ProductImgCon1'>
                    <img className='ProductImg2' src={Img2}/>
                    <img className='ProductImg2' src={Img3}/>
                    </div>
                </div>
                <div className='ProductImgConRight'>
                    <h1>100$ <b style={{fontSize:20, fontWeight:"lighter"}}>inclusive of TAX</b></h1>
                    <div className='ProductRewardCon'>
                        5$ Estimated daily POWR rewards
                        <div className='ProductRewardCon1'/>
                    </div>
                    <h2>Color: Black</h2>
                    <div className='ProductColorImgCon'>
                    <img className='ProductColorImg' src={Img1}/>
                    </div>
                    <div className='ProductSizeCon'>
                    <h2>*Available in all Sizes</h2>
                    {/* <a>Size Guide</a> */}
                    </div>
                    {/* <div className='ProductSizeCon1'>
                    <div className='ProductSize'>
                      S
                    <div className='ProductSize1'/>
                    </div>
                    <div className='ProductSize'>
                      M
                    <div className='ProductSize1'/>
                    </div>
                    <div className='ProductSize'>
                      L
                    <div className='ProductSize1'/>
                    </div>
                    <div className='ProductSize'>
                      XL
                    <div className='ProductSize1'/>
                    </div>
                    <div className='ProductSize'>
                      XXL
                    <div className='ProductSize1'/>
                    </div>
                    <div className='ProductSize'>
                      3XL
                    <div className='ProductSize1'/>
                    </div>
                    </div> */}
                    <div className='ProductColorImgCon' style={{marginTop:5}}>
                      <input value={1}/>
                      <div className='ProductRewardCon2'>
                        ADD TO BASKET
                        <div className='ProductRewardCon1'/>
                      </div>
                    </div>
                    <div className='ProductShipCon'>
                      SHIPPING PROCESS
                    </div>
                    <img src={Shop} className={"ProductShipImg"}/>
                </div>
            </div>
        </div>
        <Slides/>
    </div>
  )
}

import React from 'react'
import utility1 from "../assets/utility1.png";
import utility2 from "../assets/utility2.png";
import utility3 from "../assets/utility3.png";
import utility4 from "../assets/utility4.png";
import utility5 from "../assets/utility5.png";
import utility6 from "../assets/utility6.png";

export default function Utility() {
  return (
    <div className='Utility'>
        <h1>Utility</h1>
        <span>
            <div className='UtilityCard'>
                <img src={utility1}/>
                <div className='UtilityLight'/>
                <p>Wear your NFT in the<br/> real world</p>
            </div>
            <div className='UtilityCard'>
            <img src={utility2}/>
                <div className='UtilityLight2'/>
                <p>Earn $RHOX Coin by<br/>taping on your Apparel </p>
            </div>
            <div className='UtilityCard'>
            <img src={utility3}/>
                <div className='UtilityLight'/>
                <p>Rare Apparel with a<br/>limited supply that’s sets<br/>you apart</p>
            </div>
            <div className='UtilityCard'>
                <img src={utility4}/>
                <div className='UtilityLight2'/>
                <p>Token supply<br/>Burn Mechanism</p>
            </div>
            <div className='UtilityCard'>
            <img src={utility5}/>
                <div className='UtilityLight'/>
                <p>Ability to trade your NFT<br/>on the marketplace</p>
            </div>
            <div className='UtilityCard'>
            <img src={utility6}/>
                <div className='UtilityLight2'/>
                <p>Invites to exclusive global<br/>RHOX community meetups<br/>and partner events</p>
            </div>
            
            <div className='UtilityCard1'>
                <img src={utility1}/>
                <div className='UtilityLight'/>
                <p>Wear your NFT in the real world</p>
            </div>
            <div className='UtilityCard1'>
            <img src={utility2}/>
                <div className='UtilityLight2'/>
                <p>Earn $RHOX Coin by taping on your Apparel </p>
            </div>
            <div className='UtilityCard1'>
            <img src={utility3}/>
                <div className='UtilityLight'/>
                <p>Rare Apparel with a limited supply that’s sets you apart</p>
            </div>
            <div className='UtilityCard1'>
                <img src={utility4}/>
                <div className='UtilityLight2'/>
                <p>Token supply Burn Mechanism</p>
            </div>
            <div className='UtilityCard1'>
            <img src={utility5}/>
                <div className='UtilityLight'/>
                <p>Ability to trade your NFT on the marketplace</p>
            </div>
            <div className='UtilityCard1'>
            <img src={utility6}/>
                <div className='UtilityLight2'/>
                <p>Invites to exclusive global RHOX community meet ups and partner events</p>
            </div>
        </span>
    </div>
  )
}

import React from 'react'
import './style.css'
import IntroImg from '../assets/intro.webp'
import Social from './social'

export default function Intro() {
    return (
        <div className='Intro'>
            <span>
                <h1>Introducing the RHOX MEME Coin and the lit Rhino<br />who is always “On Fleek”</h1>
                <p>$RHOX is a community driven meme coin that aims to be the largest meme coin with actual utility, collabing with all our favorite meme coins on Web3.</p>
            </span>
            <div className='Intro_Con'>
                <div className='Intro_Card'>
                    <h3>Who is Rhox?</h3>
                    <h2>One of the few existing Javan Rhinos who was held in captivity at the endangered species conservation. One day, Rhox heard Hip Hop music blasting from the conservation's speakers, the power of the sound waves triggered something in his body and he suddenly evolved to a walking, talking music blasting Rhino with Swag and decided to escape...He is now living the Urban street life of New York, crypto-head during the day and the freshest rhino on the block at night.</h2>
                </div>
                <img src={IntroImg}/>
                <div className='Intro_Card'>
                    <h3>Rhox Profile</h3>
                    <h2>
                    Age: 22<br />
                    Gender: Male<br />
                    Kingdom: Animalia<br />
                    Genus: Rhinoceros<br />
                    Species: R. sondaicus<br />
                    Rarity: Endangered, around 70<br /><br />
                    POST EVOLUTION:<br />
                    Species: Homorhynous<br />
                    Rarity: Super Endangered, 1of 1
                    </h2>
                </div>
            </div>
            <Social/>
        </div>
    )
}

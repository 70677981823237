import React,{useState} from 'react'
import Arrow from "../assets/slidearrow.png";
import Vid1 from "../assets/1.m4v";
import Vid2 from "../assets/2.m4v";
import Vid3 from "../assets/3.m4v";
import Vid4 from "../assets/4.m4v";
import Vid5 from "../assets/5.m4v";
import Vid6 from "../assets/6.m4v";
import Vid7 from "../assets/7.m4v";
import Vid8 from "../assets/8.m4v";
import Vid9 from "../assets/9.m4v";
import Vid10 from "../assets/10.m4v";
import Vid11 from "../assets/11.m4v";
import Vid12 from "../assets/12.m4v";
import Vid14 from "../assets/14.m4v";
import Vid15 from "../assets/15.m4v";
import Vid16 from "../assets/16.m4v";
import Vid17 from "../assets/17.m4v";
import Vid18 from "../assets/18.m4v";
import Modal from './model';

export default function Slides() {
  const [slide, setSlide] = useState(0);
  const [modalOpen, setModalOpen] = useState(false)
  const OnNext = (max) =>{
    if(slide == max){
      return null
    }else{
    setSlide(slide+1);
    }
  }

  const OnPrev = () =>{
    if(slide == 0){
      return null
    }else{
    setSlide(slide-1);
    }
  }

  return (
    <div className='Slides'>
        <span className='SlideCon1'>
          {slide == 0?
          <>
            <div className='Slide'>
            <video autoPlay muted src={Vid1} loop playsInline disablePictureInPicture onClick={()=>setModalOpen(true)}/>
            </div>
            <div className='Slide'>
            <video autoPlay muted src={Vid2} loop playsInline disablePictureInPicture onClick={()=>setModalOpen(true)}/>
            </div>
            <div className='Slide'>
            <video autoPlay muted src={Vid3} loop playsInline disablePictureInPicture onClick={()=>setModalOpen(true)}/>
            </div>
            <div className='Slide'>
            <video autoPlay muted src={Vid4} loop playsInline disablePictureInPicture onClick={()=>setModalOpen(true)}/>
            </div>
            <div className='Slide'>
            <video autoPlay muted src={Vid5} loop playsInline disablePictureInPicture onClick={()=>setModalOpen(true)}/>
            </div>
            <div className='Slide'>
            <video autoPlay muted src={Vid6} loop playsInline disablePictureInPicture onClick={()=>setModalOpen(true)}/>
            </div>
            </>
            : slide == 1?
            <>
            <div className='Slide'>
            <video autoPlay muted src={Vid7} loop playsInline disablePictureInPicture onClick={()=>setModalOpen(true)}/>
            </div>
            <div className='Slide'>
            <video autoPlay muted src={Vid8} loop playsInline disablePictureInPicture onClick={()=>setModalOpen(true)}/>
            </div>
            <div className='Slide'>
            <video autoPlay muted src={Vid9} loop playsInline disablePictureInPicture onClick={()=>setModalOpen(true)}/>
            </div>
            <div className='Slide'>
              <video autoPlay muted src={Vid10} loop playsInline disablePictureInPicture onClick={()=>setModalOpen(true)}/>
            </div>
            <div className='Slide'>
            <video autoPlay muted src={Vid11} loop playsInline disablePictureInPicture onClick={()=>setModalOpen(true)}/>
            </div>
            <div className='Slide'>
            <video autoPlay muted src={Vid12} loop playsInline disablePictureInPicture onClick={()=>setModalOpen(true)}/>
            </div>
            </>
            :
            <>
            <div className='Slide'>
              <video autoPlay muted src={Vid14} loop playsInline disablePictureInPicture onClick={()=>setModalOpen(true)}/>
            </div>
            <div className='Slide'>
            <video autoPlay muted src={Vid15} loop playsInline disablePictureInPicture onClick={()=>setModalOpen(true)}/>
            </div>
            <div className='Slide'>
            <video autoPlay muted src={Vid16} loop playsInline disablePictureInPicture onClick={()=>setModalOpen(true)}/>
            </div>
            <div className='Slide'>
            <video autoPlay muted src={Vid17} loop playsInline disablePictureInPicture onClick={()=>setModalOpen(true)}/>
            </div>
            <div className='Slide'>
            <video autoPlay muted src={Vid18} loop playsInline disablePictureInPicture onClick={()=>setModalOpen(true)}/>
            </div>
            <div className='Slide'>
            <video autoPlay muted src={Vid2} loop playsInline disablePictureInPicture onClick={()=>setModalOpen(true)}/>
            </div>
            </>
          }
        </span>
        <span className='SlideCon2'>
          {slide == 0?
          <>
            <div className='Slide'>
            <video autoPlay muted src={Vid1} loop playsInline disablePictureInPicture onClick={()=>setModalOpen(true)}/>
            </div>
            <div className='Slide'>
            <video autoPlay muted src={Vid2} loop playsInline disablePictureInPicture onClick={()=>setModalOpen(true)}/>
            </div>
            </>
            : slide == 1?
            <>
            <div className='Slide'>
            <video autoPlay muted src={Vid3} loop playsInline disablePictureInPicture onClick={()=>setModalOpen(true)}/>
            </div>
            <div className='Slide'>
            <video autoPlay muted src={Vid4} loop playsInline disablePictureInPicture onClick={()=>setModalOpen(true)}/>
            </div>
            </>
            : slide == 2?
            <>
            <div className='Slide'>
            <video autoPlay muted src={Vid5} loop playsInline disablePictureInPicture onClick={()=>setModalOpen(true)}/>
            </div>
            <div className='Slide'>
            <video autoPlay muted src={Vid6} loop playsInline disablePictureInPicture onClick={()=>setModalOpen(true)}/>
            </div>
            </>
            : slide == 3?
            <>
            <div className='Slide'>
            <video autoPlay muted src={Vid7} loop playsInline disablePictureInPicture onClick={()=>setModalOpen(true)}/>
            </div>
            <div className='Slide'>
            <video autoPlay muted src={Vid8} loop playsInline disablePictureInPicture onClick={()=>setModalOpen(true)}/>
            </div>
            </>
            : slide == 4?
            <>
            <div className='Slide'>
            <video autoPlay muted src={Vid9} loop playsInline disablePictureInPicture onClick={()=>setModalOpen(true)}/>
            </div>
            <div className='Slide'>
            <video autoPlay muted src={Vid10} loop playsInline disablePictureInPicture onClick={()=>setModalOpen(true)}/>
            </div>
            </>
            : slide == 5?
            <>
            <div className='Slide'>
            <video autoPlay muted src={Vid11} loop playsInline disablePictureInPicture onClick={()=>setModalOpen(true)}/>
            </div>
            <div className='Slide'>
            <video autoPlay muted src={Vid12} loop playsInline disablePictureInPicture onClick={()=>setModalOpen(true)}/>
            </div>
            </>
            : slide == 6?
            <>
            <div className='Slide'>
            <video autoPlay muted src={Vid14} loop playsInline disablePictureInPicture onClick={()=>setModalOpen(true)}/>
            </div>
            <div className='Slide'>
            <video autoPlay muted src={Vid15} loop playsInline disablePictureInPicture onClick={()=>setModalOpen(true)}/>
            </div>
            </>
            :
            <>
            <div className='Slide'>
            <video autoPlay muted src={Vid16} loop playsInline disablePictureInPicture onClick={()=>setModalOpen(true)}/>
            </div>
            <div className='Slide'>
            <video autoPlay muted src={Vid17} loop playsInline disablePictureInPicture onClick={()=>setModalOpen(true)}/>
            </div>
            </>
          }
        </span>
        <span className='SlideCon3'>
          {slide == 0?
            <div className='Slide'>
            <video autoPlay muted src={Vid1} loop playsInline disablePictureInPicture onClick={()=>setModalOpen(true)}/>
            </div>
            : slide == 1?
            <div className='Slide'>
            <video autoPlay muted src={Vid2} loop playsInline disablePictureInPicture onClick={()=>setModalOpen(true)}/>
            </div>
            : slide == 2?
            <div className='Slide'>
            <video autoPlay muted src={Vid3} loop playsInline disablePictureInPicture onClick={()=>setModalOpen(true)}/>
            </div>
            : slide == 3?
            <div className='Slide'>
            <video autoPlay muted src={Vid4} loop playsInline disablePictureInPicture onClick={()=>setModalOpen(true)}/>
            </div>
            : slide == 4?
            <div className='Slide'>
            <video autoPlay muted src={Vid5} loop playsInline disablePictureInPicture onClick={()=>setModalOpen(true)}/>
            </div>
            : slide == 5?
            <div className='Slide'>
            <video autoPlay muted src={Vid6} loop playsInline disablePictureInPicture onClick={()=>setModalOpen(true)}/>
            </div>
            : slide == 6?
            <div className='Slide'>
            <video autoPlay muted src={Vid7} loop playsInline disablePictureInPicture onClick={()=>setModalOpen(true)}/>
            </div>
            : slide == 7?
            <div className='Slide'>
            <video autoPlay muted src={Vid8} loop playsInline disablePictureInPicture onClick={()=>setModalOpen(true)}/>
            </div>
            :
            slide == 8?
            <div className='Slide'>
            <video autoPlay muted src={Vid9} loop playsInline disablePictureInPicture onClick={()=>setModalOpen(true)}/>
            </div>
            : slide == 9?
            <div className='Slide'>
            <video autoPlay muted src={Vid10} loop playsInline disablePictureInPicture onClick={()=>setModalOpen(true)}/>
            </div>
            : slide == 10?
            <div className='Slide'>
            <video autoPlay muted src={Vid11} loop playsInline disablePictureInPicture onClick={()=>setModalOpen(true)}/>
            </div>
            : slide == 11?
            <div className='Slide'>
            <video autoPlay muted src={Vid12} loop playsInline disablePictureInPicture onClick={()=>setModalOpen(true)}/>
            </div>
            : slide == 12?
            <div className='Slide'>
            <video autoPlay muted src={Vid14} loop playsInline disablePictureInPicture onClick={()=>setModalOpen(true)}/>
            </div>
            : slide == 13?
            <div className='Slide'>
            <video autoPlay muted src={Vid15} loop playsInline disablePictureInPicture onClick={()=>setModalOpen(true)}/>
            </div>
            : slide == 14?
            <div className='Slide'>
            <video autoPlay muted src={Vid16} loop playsInline disablePictureInPicture onClick={()=>setModalOpen(true)}/>
            </div>
            : slide == 15?
            <div className='Slide'>
            <video autoPlay muted src={Vid17} loop playsInline disablePictureInPicture onClick={()=>setModalOpen(true)}/>
            </div>
            : slide == 16?
            <div className='Slide'>
            <video autoPlay muted src={Vid18} loop playsInline disablePictureInPicture onClick={()=>setModalOpen(true)}/>
            </div>
            :
            <div className='Slide'>
            <video autoPlay muted src={Vid2} loop playsInline disablePictureInPicture onClick={()=>setModalOpen(true)}/>
            </div>
          }
        </span>
        <div className='SliderCon'>
          <img src={Arrow} onClick={()=>OnPrev()}/>
          <div className='SliderLineCon'>
            <div className='SliderLine' style={{width: slide == 0? "33.33333333333333%" : slide == 1? "66.66666666666667%": "100%"}}/>
          </div>
          <img src={Arrow} style={{transform:"rotate(180deg)"}} onClick={()=>OnNext(2)}/>
        </div>
        <div className='SliderCon0'>
          <img src={Arrow} onClick={()=>OnPrev()}/>
          <div className='SliderLineCon'>
            <div className='SliderLine' style={{width: slide == 0? "12.5%" : slide == 1? "25%": slide == 2? "37.5%": slide == 3? "50%": slide == 4? "62.5%": slide == 5? "75%": slide == 6? "87.5%": "100%"}}/>
          </div>
          <img src={Arrow} style={{transform:"rotate(180deg)"}} onClick={()=>OnNext(7)}/>
        </div>
        <div className='SliderCon1'>
          <img src={Arrow} onClick={()=>OnPrev()}/>
          <div className='SliderLineCon'>
            <div className='SliderLine' style={{width: slide == 0? "5.8824%" : slide == 1? "11.7648%": slide == 2? "17.6472%" : slide == 3? "23.5296%": slide == 4? "29.412%" : slide == 5? "35.2944%" : slide == 6? "41.1768%" : slide == 7? "47.0592%": slide == 8? "52.9416%": slide == 9? "58.824%": slide == 10? "64.7064%": slide == 11? "70.5888%": slide == 12? "76.4712%": slide == 13? "82.3536%": slide == 14? "88.236%": slide == 15? "91.1184%": slide == 16? "94.144%" : "100%"}}/>
          </div>
          <img src={Arrow} style={{transform:"rotate(180deg)"}} onClick={()=>OnNext(17)}/>
        </div>
        <Modal show={modalOpen} onClose={()=>setModalOpen(false)}/>
    </div>
  )
}

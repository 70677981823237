import React from 'react'
import './style.css'

export default function Tokenomics() {
  return (
    <div className='Tokenomics'>
        <div className='Tokenomics_Img'/>
    </div>
  )
}

import React from 'react'
import rarity1 from "../assets/rarity1.png";
import rarity2 from "../assets/rarity2.png";
import rarity3 from "../assets/rarity3.png";

export default function Rarity() {
  return (
    <div className='Rarity'>
        <h1>Rarity</h1>
        <span>
            <div className='RarityCard'>
                <div className='RarityTopCard'>
                <img src={rarity1} />
                <div className='RarityLight' style={{backgroundColor:"#F8BF16"}}/>
                <h1>Royals</h1>
                </div>
                <div className='RarityCardCon'>
                <h2 className='RarityText1'>Royally Rare!!! 50/50 NFTs<br/>and physical apparels Only.</h2>
                <h2 className='RarityText2'>Royally Rare!!! 50/50 NFTs and physical apparels Only.</h2>
                <ul>
                    <li>Earn $RHOX up to 3 Times a week.</li>
                    <li>The rarest designs buy top artist, only 50 NFTS of the same designs will ever be produced.</li>
                    <li>Auto-Whitelisted for early access to the Monthly NFT/Apparel drops (2hrs before public access).</li>
                    <li>Eligible to enter the Quarterly RHOX Raffle Draw to win NFT & Coins.</li>
                    <li>Invite to Access Discord RHOX Royals & Legends Group.</li>
                </ul>
                </div>
            </div>
            <div className='RarityCard'>
                <div className='RarityTopCard'>
                <img src={rarity2} />
                <div className='RarityLight2'/>
                <h1>Legends</h1>
                </div>
                <div className='RarityCardCon'>
                <h2 className='RarityText1'>Be a Legend!!! 100/100 NFTs<br/>and physical apparels Only.</h2>
                <h2 className='RarityText2'>Be a Legend!!! 100/100 NFTs and physical apparels Only.</h2>
                <ul>
                    <li>Earn $RHOX up to 2 Times a week.</li>
                    <li>Rare designs buy top artist, only 100 NFTS of the same designs will ever be produced.</li>
                    <li>Eligible to enter the Quarterly RHOX Raffle Draw to win NFT & Coins</li>
                    <li>Invite to Access Discord RHOX Royals & Legends Group</li>
                </ul>
                </div>
            </div>
            <div className='RarityCard'>
                <div className='RarityTopCard'>
                <img src={rarity3} />
                <div className='RarityLight' style={{backgroundColor:"#F8BF16"}}/>
                <h1>Elite</h1>
                </div>
                <div className='RarityCardCon'>
                <h2 className='RarityText1'>Elite Status!!! 150/150 NFTs<br/> and physical apparels Only.</h2>
                <h2 className='RarityText2'>Elite Status!!! 150/150N FTs and physical apparels Only.</h2>
                <ul>
                    <li>Earn $RHOX once a week.</li>
                    <li>Common designs buy top artist, only 150 NFTS of the same designs will ever be produced.</li>
                </ul>
                </div>
            </div>
        </span>
    </div>
  )
}

import React from 'react'
import './style.css'
import Rino from "../assets/ezgif.com-gif-maker (2).gif";
// import Videos from "../assets/staking.mp4";
import Videos from "../assets/road.mp4";

export default function Road() {
  return (
    <div className='Staking'>
      <span>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
          {/* <h2>Roadmap</h2>
          <div className='Staking_Phase'>
            <div>
              <h4>Phase 1</h4>
              <ul>
                <li>Community building</li>
                <li>$RHOX Airdrop Campaign</li>
                <li>Zealy & Galaxe Quests</li>
                <li>Private Sale</li>
                <li>Airdrop to community</li>
                <li>DEX and CEX listing</li>
                <li>Wear & Earn Testing</li>
                <li>Colabs with Artists</li>
              </ul>
            </div>
            <div className='Staking_Phase2'>
              <h4>Phase 2</h4>
              <ul>
                <li>Riotfunk Wallet/App Launch</li>
                <li>1st Apparel  Drop “Genesis Drop”</li>
                <li>Start earning via Proof of Ownership</li>
                <li>Meet ups in California, London and Dubai</li>
                <li>“Save the Rhinos” Donation</li>
                <li>Monthly Drops</li>
              </ul>
            </div>
          </div> */}
        </div>
        <div className='FlexContainer' />
        <video src={Videos} autoPlay loop muted />
        <img className='HeroImage' src={Rino} />
        {/* <div className='HeroLight1' />
        <div className='HeroLight2' /> */}
      </span>
    </div>
  )
}

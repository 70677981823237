import React from 'react'
import tshirt1 from "../assets/tshirt1.png"
import Vid10 from "../assets/13.m4v";

export default function About() {
  return (
    <div className='About'>
      <span>
      <div className='AboutCard'>
        <h1>Bespoke apparels by the
        greatest designers and<br/>NFT artists.. Collabs with<br/>popular street wear and<br/>luxury brands</h1>
        <p>Wear & Earn  $RHOX<br/> via the PoO (Proof of Ownership) Mechanism</p>
      </div>
      </span>
      <div className='AboutImgCon'>
        <video disablePictureInPicture src={Vid10} loop autoPlay muted playsInline/>
      </div>
    </div>
  )
}
